var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zch-message-wrapper",class:_vm.hintWrapperClass,style:(_vm.lastStyle)},[_c('div',{staticClass:"zch-message",class:_vm.messageSenderClass},[(
        _vm.messageSender === "_USER" &&
        _vm.step.stepId === _vm.currentStep - 1 &&
        !_vm.awaitingResponse &&
        !_vm.isAuthEmailStepCompleted &&
        !_vm.isAppending
      )?_c('div',{staticClass:"zch-rounded-avatar undo",on:{"click":function($event){return _vm.undoStep()}}},[_c('img',{attrs:{"alt":"Undo","src":require("../../assets/undo.svg")}})]):_vm._e(),_c('span',{staticClass:"zch-message-text"},[_vm._v(_vm._s(_vm.text))])]),(_vm.messageSender === "_ZAK" && _vm.isHintAvailable)?_c('div',{staticClass:"zch-message-wrapper-hint"},[(
        _vm.step.stepId === _vm.currentStep && _vm.$parent.$data.steps[_vm.step.stepId].hint
      )?_c('img',{staticClass:"hint",attrs:{"alt":"","src":require("../../assets/hint-icon.svg")},on:{"click":_vm.toggleHint}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }