<template>
  <div
    class="zch-message zch-typing-message"
    v-bind:class="messageSenderClass"
    v-bind:style="messageStyle"
  >
    <div class="zch-typing-anim" v-bind:class="messageSenderClass">
      <span
        v-bind:key="i"
        v-for="i in 3"
        :style="getAnimShapeOpacity(i - 1)"
        class="zch-anim-shape"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingMessage",
  props: {
    messageSenderClass: String,
    messageStyle: String,
  },
  data() {
    return {
      shapeStatus: new Array(3).fill(undefined).map((val, id) => id),
      shapeOpacity: [0.4, 0.65, 0.9],
    };
  },
  methods: {
    updateAnimShapes() {
      for (let i = 0; i < 3; i++) {
        this.shapeStatus[i]++;
        if (this.shapeStatus[i] > 2) {
          this.shapeStatus[i] = 0;
        }
      }
      this.$forceUpdate();
    },

    getAnimShapeOpacity(index) {
      let shape = this.shapeStatus[index];
      return "opacity: " + this.shapeOpacity[shape];
    },
    playTypingAnimation() {
      setTimeout(() => {
        this.updateAnimShapes();
        this.playTypingAnimation();
      }, 400);
    },
  },
  mounted() {
    this.playTypingAnimation();
  },
};
</script>

<style lang="scss" scoped>
.zch-typing-message {
  padding: 25.5px 50px;
  margin-top: 12px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 135px;
  }
}
.zch-typing-anim {
  display: flex;
  justify-content: center;
  width: 100%;
}

.zch-anim-shape {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: #ffffff;

  .zch-bot-message > & {
    background-color: $color-bittersweet;
  }
  transition-property: opacity;
}

.zch-anim-shape:not(:first-child) {
  margin-left: 5px;
}
</style>
