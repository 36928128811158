<template>
  <div
    class="zch-message-wrapper"
    v-bind:style="lastStyle"
    v-bind:class="hintWrapperClass"
  >
    <div class="zch-message" v-bind:class="messageSenderClass">
      <div
        v-if="
          messageSender === `_USER` &&
          step.stepId === currentStep - 1 &&
          !awaitingResponse &&
          !isAuthEmailStepCompleted &&
          !isAppending
        "
        @click="undoStep()"
        class="zch-rounded-avatar undo"
      >
        <img alt="Undo" src="../../assets/undo.svg" />
      </div>
      <span class="zch-message-text">{{ text }}</span>
    </div>
    <div
      class="zch-message-wrapper-hint"
      v-if="messageSender === `_ZAK` && isHintAvailable"
    >
      <img
        class="hint"
        v-if="
          step.stepId === currentStep && $parent.$data.steps[step.stepId].hint
        "
        @click="toggleHint"
        alt=""
        src="../../assets/hint-icon.svg"
      />
    </div>
  </div>
</template>

<script>
import { stateHandler } from "../../main";

export default {
  name: "Message",
  props: {
    lastStyle: String,
    messageSender: String,
    text: String,
    step: Object,
    hint: String,
    index: Number,
  },
  methods: {
    undoStep() {
      if (
        this.$parent.$data.awaitingResponse ||
        this.$parent.$data.isAppendingMessages
      ) {
        return;
      }
      stateHandler.$emit("onUserUndoMessage");
    },
    toggleHint() {
      stateHandler.$emit("onUserToggleList", "hint");
    },
  },
  computed: {
    messageSenderClass() {
      return this.messageSender === "_ZAK"
        ? "zch-bot-message"
        : "zch-user-message";
    },
    currentStep() {
      return this.$parent.conversation.currentStep;
    },
    awaitingResponse() {
      return this.$parent.awaitingResponse;
    },
    isAuthEmailStepCompleted() {
      return this.$parent.conversation.completed && this.$parent.auth.email;
    },
    isHintAvailable() {
      return !(
        this.$parent.$data.typingAnim.awaitingSendersMessage !== "_USER" ||
        this.index !== this.step.zakMessages.length - 1
      );
    },
    isCurrentStep() {
      return this.step.stepId === this.currentStep;
    },
    hintWrapperClass() {
      return this.isHintAvailable &&
        this.$parent.conversation.currentStep === this.step.stepId &&
        this.hint
        ? "has-hint"
        : "";
    },
    isAppending() {
      return this.$parent.$data.isAppendingMessages;
    },
  },
};
</script>

<style lang="scss">
.zch-message-wrapper {
  display: flex;
  align-items: center;
  &-hint {
    left: 60%;
    cursor: pointer;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      max-height: 47px;
      margin-left: 10px;
    }
    &:hover {
      transition: 500ms;
      opacity: 0.6;
    }
    @media screen and (max-width: 992px) {
      left: 65%;
    }
  }
  &:not(:first-child) {
    margin-top: 12px;
  }
}
.zch-message {
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  max-width: 80%;
  height: max-content;
  align-items: center;

  animation-name: fly-in;
  animation-duration: 1s;

  &-text {
    align-self: center;
    width: fit-content;
    font-family: Muli, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #0f0f0f;
    white-space: pre-wrap;

    @media screen and (max-width: 992px) {
      height: fit-content;
    }
  }

  @media screen and (max-width: 992px) {
    max-width: 80%;
    height: max-content;
  }
}

.zch-bot-message {
  border-radius: 0 18px 18px 18px;
  margin-left: 20%;
  @media screen and (max-width: 992px) {
    margin-left: 5%;
  }

  & > .zch-rounded-avatar {
    background-position: center;
  }
}

.zch-user-message {
  border-radius: 50px 50px 0 50px;
  margin-left: auto;
  margin-right: 20%;

  background-color: #80d1b6;
  //background-image: linear-gradient(93deg, #b7eadc -52%, #7bd9ba 56%);

  & > span {
    color: #000;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 60%;
  }

  @media screen and (max-width: 992px) {
    margin-right: 5%;
  }
}

.zch-rounded-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: white;
  transition: 500ms;
  margin-right: 20px;
  cursor: default;
  & > .hint {
    width: 100%;
    padding: 20%;
    border-radius: 50%;
    background-color: $color-bittersweet;
    cursor: pointer;
    &:hover {
      background-color: $color-bittersweet-light;
      transition: 500ms;
    }
  }
  &.undo {
    cursor: pointer;
    & > img {
      width: 18px;
    }
  }
}
</style>
