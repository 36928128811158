<template>
  <div class="zch-background" />
</template>

<script>
export default {
  name: "Background",
};
</script>

<style lang="scss" scoped>
.zch-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000077;
  top: 0;
  z-index: 0;
  transition: 1000ms;
  margin-top: 0;
}
</style>
