<template>
  <div :class="displayClass" class="zch-login-modal">
    <p>
      We've just emailed you a code! Please enter it here to resume your Zakat
      calculation.
    </p>
    <div v-if="error" class="error">
      The code you entered was not recognised. Please try again.
    </div>
    <input
      v-model="token"
      type="number"
      pattern="\+?[0-9\- ()]*"
      :disabled="inputDisabled"
      ref="authcode"
    />
    <button v-bind:class="buttonClass" @click="logIn">
      <LoadingAnimation
        v-if="awaitingResponse"
        :isTiny="true"
        color="#ffffff"
      />
      <span v-else> Login </span>
    </button>
  </div>
</template>
<script>
import AuthAPI from "@/api/auth";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { stateHandler } from "../../main";

export default {
  name: "LoginModal",
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingAnimation,
  },
  data() {
    return {
      isDisplayed: false,
      token: "",
      session: "",
      awaitingResponse: false,
      error: false,
    };
  },
  methods: {
    displaySelf() {
      setTimeout(() => {
        this.isDisplayed = true;
        this.$refs.authcode.focus();
      }, 500);
    },

    async logIn() {
      if (!this.isValidInput || this.awaitingResponse) {
        return;
      }

      this.awaitingResponse = true;
      this.error = false;
      try {
        const response = await AuthAPI.confirmSession(
          this.email,
          this.token.trim()
        );
        if (response.data.success) {
          return stateHandler.$emit("onLoginSucceed", {
            idToken: response.data.id_token,
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
          });
        }

        this.error = true;
      } catch (err) {
        this.error = true;
        this.fetchSession();
      } finally {
        this.awaitingResponse = false;
      }
    },

    fetchSession() {
      AuthAPI.startSignup(this.email);
    },
  },
  computed: {
    displayClass() {
      return this.isDisplayed ? "displayed" : "";
    },

    isValidInput() {
      return this.token.length == 6;
    },

    buttonClass() {
      return this.isValidInput ? "" : "disabled";
    },

    inputDisabled() {
      return this.awaitingResponse ? "disabled" : null;
    },
  },
  mounted() {
    this.displaySelf();
    this.fetchSession();
  },
};
</script>
<style lang="scss" scoped>
.zch-login-modal {
  @include list;
  flex-direction: column;
  font-family: Muli, sans-serif;
  color: $color-cod-gray;
  bottom: -100%;
  opacity: 0;
  transition: 1000ms;
  &.displayed {
    bottom: 0;
    opacity: 1;
    overflow-x: hidden;
    @media screen and (min-width: 992px) {
      overflow: hidden;
      border-radius: 18px;
      top: unset;
      bottom: 29px;
      margin: 0 20%;
      width: 60%;
    }
  }
  p,
  .error {
    text-align: center;
  }
  .error {
    color: #ffb3b3;
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    margin-top: 12px;
    border-radius: 50px;
    border: solid 1px #c5eee0;
    background-color: #f5fffc;
    font-family: Muli, sans-serif;
    font-size: 18px;
    line-height: 1;
    text-align: center;
    color: #2e9573;
    @media screen and (min-width: 992px) {
      margin-top: 0;
    }
  }
  button {
    @include button;
    margin-top: 23px;
    margin-bottom: 30px;
    border: none;

    @media screen and (max-width: 992px) {
      margin-bottom: 0;
    }

    &:focus {
      outline: none;
    }

    &.disabled {
      background: #b3b3b3;
      box-shadow: none;
      opacity: 0.2;
    }
  }
}
::placeholder {
  color: #2e9573;
  opacity: 0.5;
}
::-ms-input-placeholder {
  color: #2e9573;
  opacity: 0.5;
}
</style>
