<template>
  <div class="zch-hint" v-bind:class="getHintClass">
    <div class="zch-hint-wrapper">
      <img src="../../assets/info.svg" alt="" />
      <div class="zch-hint-wrapper-text">
        {{ getHint }}
      </div>
      <div class="zch-hint-wrapper-btn" @click="leaveHint">GOT IT</div>
    </div>
  </div>
</template>

<script>
import { stateHandler } from "../../main";

export default {
  name: "Hint",
  data() {
    return {
      hidden: true,
    };
  },
  methods: {
    leaveHint() {
      stateHandler.$emit("onUserToggleList", "hint");
    },
  },
  computed: {
    getHint() {
      const currentStep = this.$parent.$data.conversation.currentStep;
      return this.$parent.$data.steps[currentStep].hint;
    },
    getHintClass() {
      return this.hidden ? "hidden" : "";
    },
  },
  mounted() {
    setTimeout(() => {
      this.hidden = false;
    }, 100);
  },
  beforeDestroy() {
    this.hidden = true;
  },
};
</script>

<style lang="scss" scoped>
.zch-hint {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    transition: 500ms;
    bottom: -16%;
    opacity: 0;
  }

  &-wrapper {
    height: unset;
    @include list;
    flex-direction: column;
    overflow-y: initial;
    width: 100%;
    top: auto;
    color: $color-cod-gray;
    font-family: Montserrat, sans-serif;
    padding: 20px;
    align-content: initial;
    &-text {
      overflow-wrap: break-word;
      text-align: center;
      margin: 30px;
    }

    &-btn {
      @include button;
      width: max-content;
      align-self: center;
    }

    @media screen and (min-width: 992px) {
      width: 60%;
      overflow: auto;
      border-radius: 24px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: min-content;
    }
  }
}
</style>
