<template>
  <div class="zch-switch-btn" v-bind:class="switchClass" @click="updateSwitch">
    <div class="zch-switch-btn-toggler"></div>
  </div>
</template>

<script>
import { stateHandler } from "../../main";

export default {
  name: "SwitchButton",
  props: {
    defaultValue: {
      type: Boolean,
      default: true,
    },
    callback: {
      type: String,
      default: "onSwitchButtonUpdate",
    },
  },
  data() {
    return {
      value: this.defaultValue,
    };
  },
  methods: {
    updateSwitch() {
      this.value = !this.value;
      stateHandler.$emit(this.callback, this.value);
    },
  },
  computed: {
    switchClass() {
      return !this.value ? "toggled" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.zch-switch-btn {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 50px;
  background-color: #f8f9fb;
  border: 1px solid $color-montecarlo;
  width: 60px;
  height: 30px;
  cursor: pointer;

  &.toggled {
    justify-content: flex-end;
  }

  &-toggler {
    background-color: $color-montecarlo;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: 500ms;

    &:hover {
      opacity: 0.7;
      transition: 500ms;
    }
  }
}
</style>
